<script lang="ts" setup>
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { HIGH_YIELD_PAGE_STRATEGY_ID } from '~/config'
import { formatAmount, formatMoney, formatPrice, formatReward, lamportsToSol } from '~/utils'

function formatSum(val: number) {
  if (val > 1000) {
    return formatMoney(val)
  }
  return formatAmount(val)
}

const router = useRouter()

const toggleSidebar = inject<() => void>('toggleSidebar')

const coinRateStore = useCoinRateStore()
const jsolPrice = computed(() => coinRateStore.jpool?.price || 0)

const directStakeStore = useDirectStakeStore()
const highYieldUser = computed(() => directStakeStore.highYieldUser)

const claimableAmount = computed(() => {
  return Number(highYieldUser.value?.claimable) || 0
})

const claimableAmountJsol = computed(() => {
  return highYieldUser.value && Number(claimableAmount.value) ? formatReward(Number(claimableAmount.value)) : 0
})

const claimableAmountToUsd = computed(() => {
  const usd = Number(claimableAmount.value) / LAMPORTS_PER_SOL * jsolPrice.value
  return usd < 0.01 ? '$<0.01' : `$${formatPrice(usd, 2, 2)}`
})

const stake = computed(() => directStakeStore.strategyStakes.find(s => s.strategyId === HIGH_YIELD_PAGE_STRATEGY_ID))
const stakeAmount = computed(() => lamportsToSol(stake.value?.availableAmount ? +stake.value?.availableAmount : 0))
const amount = computed(() => formatSum(stakeAmount.value))

const jsolToUsd = computed(() => formatPrice(Number(stakeAmount.value * jsolPrice.value) || 0, 2, 2))

function handleClick() {
  router.push(`/high-yield`)
  if (toggleSidebar) {
    toggleSidebar()
  }
}
</script>

<template>
  <template v-if="stake">
    <div class="setting-item__title">
      {{ $t('sidebar.myHighYieldStake') }}
    </div>
    <div class="staking-card staking-direct-card">
      <div v-if="claimableAmount" class="staking-card__top">
        <div class="claimable-usd">
          {{ claimableAmountToUsd }}
        </div>
        <div class="claimable-jsol">
          {{ claimableAmountJsol }} JSOL
        </div>

        <div class="stake-status active">
          Claimable
        </div>
      </div>
      <div class="staking-card__info">
        <div class="info-item">
          <span class="label">{{ $t('sidebar.stake') }}</span>
          <span class="price">${{ jsolToUsd }}</span>
          <span>{{ amount }} JSOL</span>
        </div>
      </div>

      <div class="staking-card__actions">
        <j-btn variant="primary" pill @click="handleClick()">
          Add deposit
          <i-app-add-square />
        </j-btn>
      </div>
    </div>
  </template>
</template>

<style lang="scss">
.staking-direct-card {
  .claimable-usd {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #ababab;
  }

  .claimable-jsol {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-right: auto;
  }
}
</style>
