<script lang="ts" setup>
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { formatAmount, formatPrice, lamportsToSol, shortenAddress } from '~/utils'

const router = useRouter()
const route = useRoute()

const toggleSidebar = inject<() => void>('toggleSidebar')

const validatorsStore = useValidatorsStore()
const directStakeStore = useDirectStakeStore()
const directStakeFiltersStore = useDirectStakeFiltersStore()

const coinRateStore = useCoinRateStore()
const jsolPrice = computed(() => coinRateStore.jpool?.price || 0)

const showMore = ref(false)

const stakes = computed(() => {
  return directStakeStore.directStakes.map((stake) => {
    return {
      ...stake,
      validator: validatorsStore.allValidators.find(v => v.voteId === stake.voteId),
    }
  })
    .filter(stake => Number(stake.availableAmount) > 0)
})

const showedStakes = computed(() => stakes.value.length > 2 && !showMore.value ? [...stakes.value].splice(0, 2) : stakes.value)

function jsolToUsd(lamports = 0) {
  const amount = lamports / LAMPORTS_PER_SOL
  const stakePrice = formatPrice(Number(amount * jsolPrice.value), 2, 2) || 0
  return formatPrice(Number(stakePrice), 2, 2)
}

function amountNormalized(lamports = 0) {
  const num = Number(lamportsToSol(lamports))
  return num >= 0 ? formatAmount(num, num < 1 ? 5 : 3) : ''
}

function handleClick(voteId?: string) {
  const validator = validatorsStore.formattedValidators.find(v => v.voteId === voteId)
  if (validator) {
    directStakeFiltersStore.selectedValidator = validator
  }
  const path = route.path
  let query = {}
  if (path.includes('direct-staking') && validator?.voteId) {
    query = {
      ...route.query,
      vote: validator?.voteId,
    }
  }
  router.push({ path: '/direct-staking', query })
  if (toggleSidebar) {
    toggleSidebar()
  }
}
</script>

<template>
  <template v-if="stakes.length > 0">
    <div class="setting-item__title">
      {{ $t('sidebar.myDirectStake') }}
    </div>
    <div v-for="stake in showedStakes" :key="stake.voteId" class="staking-card staking-direct-card">
      <div class="staking-card__top">
        <div class="validator-name">
          {{ stake.validator?.name ?? shortenAddress(String(stake.voteId), 7) }}
        </div>
        <j-avatar :image="stake.validator?.iconUrl || stake.validator?.image || ''" size="30px" />
      </div>

      <div class="staking-card__info">
        <div class="info-item">
          <span class="label">{{ $t('sidebar.stake') }}</span>
          <span class="price">${{ jsolToUsd(Number(stake.availableAmount)) }}</span>
          <span>{{ amountNormalized(Number(stake.availableAmount)) }} JSOL</span>
        </div>
      </div>

      <div class="staking-card__actions">
        <j-btn variant="primary" pill @click="handleClick(stake?.voteId)">
          Add deposit
          <i-app-add-square />
        </j-btn>
      </div>
    </div>

    <j-btn
      v-if="stakes?.length > 2"
      variant="secondary"
      pill
      class="show-more-btn"
      @click="showMore = !showMore"
    >
      <span v-if="showMore">Show less</span>
      <span v-else>Show more</span>

      <template #append>
        <i-app-accordion-arrow-up v-if="showMore" />
        <i-app-accordion-arrow-down v-else />
      </template>
    </j-btn>
  </template>
</template>

<style lang="scss">
.staking-direct-card {
  .staking-card__top {
    gap: $spacing-16;

    .j-avatar {
      min-width: 30px;
    }

    .validator-name {
      max-width: initial;
    }
  }
  .staking-card__actions {
    width: 100%;

    svg {
      margin-bottom: -2px;
    }
  }
}
</style>
