<script lang="ts" setup>
import { useWallet } from '@solana/wallet-adapter-vue'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import offIcon from '~/assets/img/icons/off-icon.svg'
import { formatAmount, formatPrice, lamportsToSol, shortenAddress } from '~/utils'

const emit = defineEmits(['refreshStakes'])

const { publicKey } = useWallet()

const showMore = ref(false)

const stakeAccountStore = useStakeAccountStore()

const loadingPubkeys = computed(() => stakeAccountStore.loadingPubkeys)

const validatorsStore = useValidatorsStore()
const getValidator = (voteId: string) => validatorsStore.allValidators.find(v => v.voteId === voteId)

const accountsFull = computed(() => {
  const accounts = stakeAccountStore.accountsFull
  const showedAccounts = accounts.length > 2 && !showMore.value ? [...accounts].splice(0, 2) : accounts
  return showedAccounts.map((acc) => {
    return {
      ...acc,
      validator: getValidator(acc.stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter),
    }
  })
})

const coinRateStore = useCoinRateStore()
const solPrice = computed(() => coinRateStore.solana?.price)

function isLoading(addr: string) {
  return loadingPubkeys.value.has(addr)
}

function amountNormalized(lamports = 0) {
  const num = Number(lamportsToSol(lamports))
  return num >= 0 ? formatAmount(num, num < 1 ? 5 : 3) : ''
}

function solToUsd(lamports = 0) {
  const amount = lamports / LAMPORTS_PER_SOL
  const stakePrice = formatPrice(Number(amount * solPrice.value), 2, 2) || 0
  return formatPrice(Number(stakePrice), 2, 2)
}

async function accountsHandler(account: { stakeAccount: StakeAccount, validator?: ValidatorData }, event: string) {
  if (event === 'stake_account_deactivate') {
    await deactivate(account.stakeAccount.pubkey.toBase58())
  }
  if (event === 'stake_account_deposit') {
    await deposit({
      stakeAccount: account.stakeAccount,
      directTarget: account.stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter,
    })
  }
  if (event === 'stake_account_withdraw') {
    await withdraw(account.stakeAccount.pubkey.toBase58(), account.stakeAccount.account.lamports)
  }
  Analytics.track({
    event,
    address: publicKey.value?.toBase58(),
    amount: amountNormalized(account.stakeAccount?.account?.lamports),
    validator_name: account.validator?.name,
    validator_vote_id: account.validator?.voteId,
  })
}

async function deactivate(address: string) {
  await stakeAccountStore.deactivate([address])
}

async function deposit({ stakeAccount, directTarget }: { stakeAccount: StakeAccount, directTarget?: string }) {
  await stakeAccountStore.depositJpool([stakeAccount], directTarget)
  emit('refreshStakes')
}

async function withdraw(address: string, lamports: number) {
  await stakeAccountStore.withdraw([{ address, lamports }])
}
</script>

<template>
  <div v-for="account in accountsFull" :key="account.stakeAccount.pubkey.toBase58()" class="staking-card">
    <div class="staking-card__top">
      <div class="validator-name">
        {{ account.validator?.name }}
      </div>
      <div class="stake-status" :class="account.state?.toLowerCase()">
        {{ account.state }}
      </div>
      <div
        v-if="account.state === 'active' || account.state === 'activating'"
        class="stake-status__icon"
        @click="accountsHandler(account, 'stake_account_deactivate')"
      >
        <j-tooltip>
          <img :src="offIcon" alt="off icon">
          <template #content>
            {{ $t('sidebar.deactivate') }}
          </template>
        </j-tooltip>
      </div>
    </div>

    <div class="staking-card__info">
      <div class="info-item">
        <span class="label">{{ $t('sidebar.stake') }}</span>
        <span class="price">${{ solToUsd(account.stakeAccount?.account?.lamports) }}</span>
        <span>{{ amountNormalized(account.stakeAccount?.account?.lamports) }} SOL</span>
      </div>
      <div class="info-item">
        <span class="label">{{ $t('sidebar.address') }} <copy-to-clipboard :text="String(account.stakeAccount.pubkey.toBase58())" /></span>
        <span>{{ shortenAddress(String(account.stakeAccount.pubkey.toBase58()), 7) }}</span>
      </div>
    </div>

    <div class="staking-card__actions">
      <j-btn
        v-if="account.state === 'active' && account.validator && account.validator.inJpool"
        pill
        size="sm"
        @click="accountsHandler(account, 'stake_account_deposit')"
      >
        {{ $t('sidebar.deposit') }}
      </j-btn>

      <j-btn
        pill
        variant="primary"
        :disabled="account.state !== 'inactive'"
        @click="accountsHandler(account, 'stake_account_withdraw')"
      >
        {{ $t('sidebar.withdraw') }}
      </j-btn>
    </div>

    <j-loading-spinner v-if="isLoading(account.stakeAccount.pubkey.toBase58())" :color="isDark ? '#fff' : '#111'" />
  </div>

  <j-btn
    v-if="stakeAccountStore.accountsFull?.length > 2"
    variant="secondary"
    pill
    class="show-more-btn"
    @click="showMore = !showMore"
  >
    <span v-if="showMore">Show less</span>
    <span v-else>Show more</span>

    <template #append>
      <i-app-accordion-arrow-up v-if="showMore" />
      <i-app-accordion-arrow-down v-else />
    </template>
  </j-btn>
</template>

<style lang="scss">
.deposit-popover {
  .popover-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;

    .btn {
      width: 100%;
    }
  }
}
.staking-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $spacing-16;
  gap: $spacing-24;
  border-radius: $spacing-12;
  border: 1px solid $secondary;
  overflow: hidden;

  &__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .validator-name {
      max-width: 174px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .stake-status {
      background-color: $yellow-50;
      padding: $spacing-4 $spacing-8;
      border-radius: $spacing-4;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: $spacing-4;

      &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $spacing-4;
        background: $secondary;
        padding: $spacing-4;
        cursor: pointer;

        & > div {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-8;

    .info-item {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $spacing-16;

      &:nth-child(1) {
        padding-bottom: $spacing-8;
        border-bottom: 1px solid $secondary;
      }
    }

    span.label {
      color: $neutral-300;
      display: flex;
      gap: $spacing-4;
    }

    span.price {
      color: #ababab;
      margin-left: auto;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    gap: $spacing-8;

    .btn {
      height: 32px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .btn-primary {
      width: 100%;
    }
  }

  .loading-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(1px);
  }

  .stake-status {
    &.active {
      background-color: $green-50;
    }
    &.inactive {
      background-color: $red-50;
    }
  }
}

body.body--dark {
  .staking-card {
    border-color: $neutral-900;
  }

  .staking-card__top .stake-status {
    background-color: $yellow-900;
    color: #fff;

    &.active {
      background-color: $green-900;
    }
    &.inactive {
      background-color: $red-900;
    }

    &__icon {
      background: $neutral-900;

      img {
        filter: invert(1);
      }
    }
  }

  .staking-card__info {
    .info-item:nth-child(1) {
      border-color: $neutral-900;
    }
    span.label {
      color: $neutral-500;

      svg {
        color: $neutral-500;
      }
    }
  }
}
</style>
